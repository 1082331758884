import { Link } from 'react-router-dom';

function HomePage() {
  return (
    <>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><a href="/login">Login</a></li>
        </ul>
      </nav>
      <h1>Welcome to Scooterbot Tesla</h1>
    </>
  );
}

export default HomePage;
