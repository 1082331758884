import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './HomePage';

function App() {
  return (
    <Router>
      <header>
        <Link to="/">Scooterbot Tesla</Link>
        <hr/>
      </header>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <footer>
        <hr />
        <ul>
          <li><Link to="/privacy">Privacy</Link></li>
          <li><Link to="/tos">Terms of Service</Link></li>
        </ul>
      </footer>
    </Router>
  );
}

export default App;
